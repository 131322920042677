<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, validate, changed, validated }"
        slim
        :detect-input="false"
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <div ref="wrapper" :class="rootClass">
            <div class="v-input__control">
                <div class="v-input__slot">
                    <div class="v-text-field__slot">
                        <label :class="labelClass">
                            {{ labelAltered }}
                        </label>
                        <content-editor
                            ref="editor"
                            :value="innerValue"
                            :auto-links="autoLinks"
                            :no-first-person="noFirstPerson"
                            :disabled="disabled"
                            :formats="formats"
                            :toolbar="toolbar"
                            :class="{
                                'content-editor-one-line': oneLine,
                                'content-editor-short': dense
                            }"
                            v-on="getListeners(validate, changed, validated)"
                        />
                    </div>
                </div>
                <v-progress-linear v-if="loading" height="2" indeterminate />
                <div class="v-text-field__details">
                    <div :class="detailsClass" role="alert">
                        <div class="v-messages__wrapper">
                            <div
                                v-if="errors.length && !observed"
                                class="v-messages__message"
                            >
                                {{ errors[0] }}
                            </div>
                        </div>
                    </div>
                    <div :class="counterClass">
                        {{ counterText }}
                    </div>
                </div>
            </div>
        </div>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';

import { ContentEditor } from '@/components/ContentEditor';
import { Watch } from '@/utils/decorators';

const AContentEditorProps = Vue.extend({
    name: 'AContentEditor',
    props: {
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        rules: {
            type: [String, Object],
            default() {
                return '';
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        observed: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        counterValue: {
            type: Function,
            default: null // eslint-disable-line quote-props
        },
        value: {
            type: String,
            default() {
                return '';
            }
        },
        autoLinks: {
            type: Boolean,
            default() {
                return true;
            }
        },
        noFirstPerson: {
            type: Boolean,
            default() {
                return true;
            }
        },
        oneLine: {
            type: Boolean,
            default() {
                return false;
            }
        },
        dense: {
            type: Boolean,
            default() {
                return false;
            }
        },
        formats: {
            type: Array<string>,
            default() {
                return ['link', 'first-person-error'];
            }
        },
        toolbar: {
            type: Array<Array<string | object>>,
            default() {
                return [['link']];
            }
        }
    }
});

@Component({
    inheritAttrs: false,
    components: {
        ValidationProvider,
        ContentEditor
    }
})
export default class AContentEditor extends AContentEditorProps {
    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
        editor: InstanceType<typeof ContentEditor>;
    };

    innerValue = '';
    isFocused = false;

    isMounted = false;
    isReady = false;
    isChanged = false;
    isValidated = false;

    get attrs() {
        return {
            label: this.labelAltered,
            ...this.$attrs
        };
    }

    get labelAltered() {
        return `${this.label}${this.required && !this.disabled ? ' *' : ''}`;
    }

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }

    get rootClass() {
        const classes = [
            'v-input-content-editor',
            'v-input',
            this.$vuetify.theme.dark ? 'theme--dark' : 'theme--light',
            'v-text-field',
            'v-text-field--filled',
            'v-text-field--is-booted',
            'v-text-field--enclosed'
        ];

        if (this.isFocused) {
            classes.push('v-input--is-focused', 'primary--text');
        }

        if (this.innerValue) {
            classes.push('v-input--is-dirty');
        }

        if (this.isChanged && !this.isValid) {
            classes.push('error--text', 'v-input--has-state');
        }

        if (this.loading) {
            classes.push('v-input--is-loading');
        }

        return classes.join(' ');
    }

    get labelClass() {
        const classes = new Set(['v-label', 'theme--light']);

        if (this.isFocused) {
            classes.add('primary--text');
            classes.add('v-label--active');
        }

        if (this.innerValue) {
            classes.add('v-label--active');
        }

        if ((this.isChanged || this.isValidated) && !this.isValid) {
            classes.add('error--text');
        }

        return [...classes].join(' ');
    }

    get detailsClass() {
        const classes = ['v-messages', 'theme--light'];

        if (!this.isValid) {
            classes.push('error--text');
        }

        return [...classes].join(' ');
    }

    get isValid() {
        return this.isMounted ? this.$refs.provider.errors.length === 0 : true;
    }

    get max() {
        return (
            (typeof this.rules === 'string'
                ? this.rules
                      .split('|')
                      .find(rule => rule.split(':')[0] === 'max')
                      ?.split(':')[1]
                : this.rules.max) || void 0
        );
    }

    get counterText() {
        if (typeof this.counterValue === 'function') {
            return this.counterValue(this.innerValue);
        }

        if (this.max) {
            return `${this.innerValue.length} / ${this.max}`;
        }

        return '';
    }

    get counterClass() {
        const classes = ['v-counter', 'theme--light'];

        if (this.counterText) {
            try {
                const [current, max] = this.counterText
                    .split('/')
                    .map((v: string) => Number(v));

                if (current > max) {
                    classes.push('error--text');
                }
            } catch {
                /* silently */
            }
        }

        return classes.join(' ');
    }

    @Watch('value')
    onValueChange(value: string) {
        if (this.innerValue !== value) {
            this.innerValue = value;

            if (this.isMounted) {
                this.$refs.provider.syncValue(this.innerValue);
            }
        }
    }

    getListeners(
        validate: (v: string) => Promise<{ valid: boolean }>,
        changed: boolean,
        validated: boolean
    ) {
        this.isChanged = changed;
        this.isValidated = validated;

        return {
            focus: () => {
                this.isFocused = true;
            },
            blur: () => {
                this.isFocused = false;

                validate(this.innerValue);
            },
            input: (value: string) => {
                if (this.innerValue !== value) {
                    this.innerValue = value;

                    this.$refs.provider.setFlags({
                        dirty: true,
                        changed: true,
                        touched: true
                    });

                    if (this.isMounted && (this.innerValue || this.isChanged)) {
                        validate(this.innerValue);
                    }

                    this.$emit('input', this.innerValue);
                }
            }
        };
    }

    mounted() {
        // turn on validation
        this.isMounted = true;

        this.innerValue = this.value;

        this.$refs.provider.syncValue(this.innerValue);
    }
}
</script>

<style lang="scss" scoped>
.v-input-content-editor::v-deep {
    > .v-input__control {
        > .v-input__slot {
            padding: 0 !important;

            > .v-text-field__slot {
                max-width: 100%;

                > .v-label {
                    top: 0px;
                    left: 0px;
                    right: auto;
                    position: absolute;
                    padding-left: 16px;
                }

                > .content-editor {
                    background-color: transparent;
                    margin-top: 26px;
                    width: 100%;

                    .ql-editor {
                        border-bottom: none !important;
                        padding: 12px !important;
                        font-family: 'DM Sans', sans-serif !important;
                        font-size: 16px !important;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        [role='progressbar'] {
            position: relative;
            top: -9px;
            margin-bottom: -2px;
        }
    }

    &.v-text-field--filled
        > .v-input__control
        > .v-input__slot
        > .v-text-field__slot
        > .v-label {
        top: 13px;

        &.v-label--active {
            top: 18px;
        }
    }
}
</style>
